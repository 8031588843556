import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import L1ButtonsWrapper from './auth';
import AnalyticsProvider from './lib/segment/AnalyticsProvider';
import { fetchAsyncPropsClient } from './util/fetch-client-props';
import { RegisteredUserContext } from './auth/global/RegisteredUserContext';
import localStorageHelper from './auth/lib/localStorage';
import type { AuthButtonsProps } from 'src/types';
import ApolloWrapper from './auth/global/ApolloWrapper';
import { useStore } from '@nanostores/preact';
import { $authLoaded, $rightButtonsReady } from './store/client';
import ErrorBoundary from './lib/ErrorBoundary';
import { getClientStore } from './util/getStore';

interface Props {
  /**
   * JSON serialized, URI encoded value of RightButtonsProps
   */
  props: string;
}

const AuthButtons: React.FC<Props> = ({ props }) => {
  const {
    clientID,
    pageTaxonomy = [],
    segmentTrackingAttributes,
    includeAuth,
    isAuthModalOverrideSet,
    authParamsOverride,
  }: AuthButtonsProps = JSON.parse(decodeURIComponent(props));

  const [asyncProps, setAsyncProps] = useState(null);
  const [renderDelayComplete, setRenderDelayComplete] = useState(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);
  const rightButtonsReady = useStore($rightButtonsReady);

  useEffect(() => {
    // check value of local storage variable and sets in context
    setIsRegisteredUser(
      localStorageHelper.getItem('userHasLoggedIn') === 'true'
    );

    // this timeout helps mitigate a "flicker" we are seeing between
    // the initial sign in/sign up buttons, and the reg cta dropdown
    // for more info: https://github.com/NerdWallet/global-markup/pull/1057#discussion_r860969132
    const timeoutId = setTimeout(() => setRenderDelayComplete(true), 100);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  // Set $authLoaded when this component is fully ready to render
  useEffect(() => {
    if (renderDelayComplete && asyncProps) $authLoaded.set(true);
  }, [renderDelayComplete, asyncProps]);

  useEffect(() => {
    let mounted = true;
    fetchAsyncPropsClient({
      pageTaxonomy,
    }).then((items) => {
      if (mounted) {
        setAsyncProps(items);
      }
    });
    return () => (mounted = false);
  }, []);

  if (!rightButtonsReady) {
    return null;
  }

  const getStore = () =>
    getClientStore(
      asyncProps,
      authParamsOverride,
      pageTaxonomy,
      clientID,
      segmentTrackingAttributes
    );

  return (
    <ErrorBoundary>
      <Provider store={getStore()}>
        <ApolloWrapper>
          <AnalyticsProvider>
            <RegisteredUserContext.Provider value={{ isRegisteredUser }}>
              <L1ButtonsWrapper
                includeAuth={includeAuth}
                isAuthModalOverrideSet={isAuthModalOverrideSet}
              />
            </RegisteredUserContext.Provider>
          </AnalyticsProvider>
        </ApolloWrapper>
      </Provider>
    </ErrorBoundary>
  );
};

export default AuthButtons;
