import { useEffect, useState } from 'preact/hooks';
import TrackedButton from './TrackedButton';

import { GLOBAL_NAV_REG_CTA_V1 } from '../lib/analytics.constants';
import SegmentImpression, {
  ImpressionType,
} from '../../lib/segment/SegmentImpression';
import useAnalytics from '../../lib/segment/useAnalytics';

import styles from './AnonButtons.module.less';
import { SEGMENT_EVENT } from '../lib/segment.constants';
import { useExperiment } from '@nerdwallet/features';
import { useSelector } from 'react-redux';
import type { ReduxStore } from '../redux/types';

const TEST_NAME = 'UEX-2346_join_button_for_personal_finance_articles_to_app';
const NERDWALLET_APP_URL = 'https://click.nerdwallet.com/3687710914/gmuytxia';
const PAGE_VERTICAL = 'Personal Finance';
const MOBILE_MEDIA_QUERY = '(max-width: 768px)';

const useIsMobileWeb = () => {
  const [isMWeb, setIsMWeb] = useState(
    () =>
      typeof window !== 'undefined' &&
      window.matchMedia(MOBILE_MEDIA_QUERY).matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(MOBILE_MEDIA_QUERY);
    const handleChange = () => setIsMWeb(mediaQuery.matches);

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return isMWeb;
};

const useExperimentVariant = (pageVertical: string, isMWeb: boolean) => {
  const { assignedVariantName, loading } = useExperiment({
    testName: TEST_NAME,
    options: {
      skip: pageVertical !== PAGE_VERTICAL || !isMWeb,
    },
  });

  return { assignedVariantName, loading };
};

const getOverrideLink = (
  variantName: string | undefined
): string | undefined => {
  return variantName === 'variant' ? NERDWALLET_APP_URL : undefined;
};

const AnonButtons: React.FC = () => {
  const pageVertical = useSelector<ReduxStore, ReduxStore['pageVertical']>(
    (store) => store.pageVertical
  );

  const isMWeb = useIsMobileWeb();

  const { assignedVariantName, loading } = useExperimentVariant(
    pageVertical,
    isMWeb
  );

  const segment = useAnalytics();
  const analyticsBase = GLOBAL_NAV_REG_CTA_V1;
  type ButtonType = 'signin' | 'register';

  const registerButtonSharedProps = {
    buttonType: 'register' as ButtonType,
    handleClick: () => {
      segment.track(SEGMENT_EVENT.ELEMENT_INTERACTION, {
        entity_name: analyticsBase.entity_names.register,
        section_name: analyticsBase.section_name,
        registration_cohort: analyticsBase.cohort,
      });
    },
    driverLocation: analyticsBase.driver_location,
    cohort: analyticsBase.cohort,
    redirectQueryParam: '?feature_discovery=nw_plus',
  };

  if (loading) return null;

  return (
    <div className={styles.container}>
      <SegmentImpression
        eventName={SEGMENT_EVENT.ELEMENT_IMPRESSION}
        eventType={ImpressionType.TRACK}
        eventProps={{
          entity_name: analyticsBase.entity_names.signin,
          section_name: analyticsBase.section_name,
          registration_cohort: analyticsBase.cohort,
        }}
      >
        <TrackedButton
          buttonName="Sign in"
          buttonType="signin"
          handleClick={() => {
            segment.track(SEGMENT_EVENT.ELEMENT_INTERACTION, {
              entity_name: analyticsBase.entity_names.signin,
              section_name: analyticsBase.section_name,
              registration_cohort: analyticsBase.cohort,
            });
          }}
          isTertiary
          driverLocation={GLOBAL_NAV_REG_CTA_V1.driver_location}
          overrideLink={getOverrideLink(assignedVariantName)}
        />
      </SegmentImpression>

      <SegmentImpression
        eventName={SEGMENT_EVENT.ELEMENT_IMPRESSION}
        eventType={ImpressionType.TRACK}
        eventProps={{
          entity_name: analyticsBase.entity_names.register,
          section_name: analyticsBase.section_name,
          registration_cohort: analyticsBase.cohort,
        }}
      >
        <div className={styles.fullCtaText}>
          <TrackedButton
            isPrimary
            buttonName="Sign up"
            overrideLink={getOverrideLink(assignedVariantName)}
            {...registerButtonSharedProps}
          />
        </div>
        <div className={styles.shortCtaText}>
          <TrackedButton
            buttonName="Join"
            overrideLink={getOverrideLink(assignedVariantName)}
            {...registerButtonSharedProps}
          />
        </div>
      </SegmentImpression>
    </div>
  );
};

export default AnonButtons;
