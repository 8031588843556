import { Suspense } from 'react';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import type { DelayedFunction } from '../hooks/useInteractionDelay';
import type { ReduxStore } from '../redux/types';
import useIsMobile from '../hooks/useIsMobile';
import AnonButtons from './AnonButtons';
import LazyReactRetry from '../../util/lazyReactRetry';

import styles from './L1Buttons.module.less';
import { LoadingSpinner } from '@nerdwallet/currency';

const MyNerdWalletButton = LazyReactRetry(
  async () => await import('./MyNerdWalletButton')
);

const NotificationBellButton = LazyReactRetry(
  async () => await import('../notifications/NotificationBell')
);

interface Props {
  onHover: DelayedFunction;
  isLoading: boolean;
}

const Buttons: React.FC<Props> = ({ onHover, isLoading }) => {
  const { isNavNarrow } = useIsMobile();

  const isLoggedIn = useSelector<ReduxStore, ReduxStore['isLoggedIn']>(
    (store) => store.isLoggedIn
  );

  const shouldRenderAnonButtons = () => {
    if (isLoggedIn) {
      return null;
    }

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <div
        className={classNames({
          [styles.renderAnonButtons]: !isNavNarrow,
        })}
      >
        <Suspense fallback={null}>
          <AnonButtons />
        </Suspense>
      </div>
    );
  };

  const renderNotificationBell = () => {
    if (!isLoggedIn) {
      return null;
    }

    return (
      <Suspense fallback={null}>
        <NotificationBellButton />
      </Suspense>
    );
  };

  const renderMyNerdwalletButton = () => (
    <Suspense fallback={null}>
      <MyNerdWalletButton onHover={onHover} />
    </Suspense>
  );

  const shouldRenderMyNerdwalletButton = () => {
    // if the experiment is active and its ONLY on mWeb and the user is
    // logged in ONLY then render the MyNWButton
    if (isNavNarrow) {
      if (isLoggedIn) return renderMyNerdwalletButton();
      return null;
    }
    if (isLoggedIn) return renderMyNerdwalletButton();

    return null;
  };

  return (
    <div className={styles.buttonsWrapper}>
      <div className={styles.actionsWrapper}>{renderNotificationBell()}</div>

      {shouldRenderAnonButtons()}
      {shouldRenderMyNerdwalletButton()}
    </div>
  );
};

export default Buttons;
