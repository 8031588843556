import { useCallback, useEffect, useMemo, useState, Suspense } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { reregisterWebPush } from '@nerdwallet/membership-features/web-push-notifications';
import { ThirdPartyAuthFailReasons } from './redux/constants';
import useInteractionDelay from './hooks/useInteractionDelay';
import type { ReduxStore } from './redux/types';
import {
  SHOW_REG_ERROR_STATUS,
  SHOW_REG_SUCCESS_STATUS,
  SHOW_REG_INBOX_SUCCESS_STATUS,
  HIDE_REG_STATUS,
} from './redux/constants';
import {
  CONTACT_US_URL,
  REG_STATUS_ERROR_COPY,
  REG_STATUS_SUCCESS_COPY,
} from './lib/constants';
import LazyReactRetry from '../util/lazyReactRetry';

import { SET_GLOBAL_NAV_STATE, WINDOW_RESIZE } from './redux/actions';
import localStorageHelper from './lib/localStorage';
import { incrementPageViews } from './lib/pageViews';
import TrackedButton from './buttons/TrackedButton';
import useAnalytics from '../lib/segment/useAnalytics';

import L1Buttons from './buttons/L1Buttons';

import styles from './index.module.less';
import ErrorBoundary from '../lib/ErrorBoundary';
import type { IncludeAuthProps } from 'src/types';

const SuccessModal = LazyReactRetry(
  async () =>
    (await import('@nerdwallet/js-auth-tools/registration')).SuccessModal
);
const ErrorModal = LazyReactRetry(
  async () =>
    (await import('@nerdwallet/js-auth-tools/registration')).ErrorModal
);
const OneTapComponent = LazyReactRetry(
  () => import('./membership/OneTapComponent')
);

interface Props {
  includeAuth: IncludeAuthProps;
  isAuthModalOverrideSet: boolean;
}

const L1ButtonsWrapper: React.FC<Props> = ({
  includeAuth,
  isAuthModalOverrideSet,
}) => {
  const isLoggedIn = useSelector<ReduxStore, ReduxStore['isLoggedIn']>(
    (store) => store.isLoggedIn
  );

  const originatingUrl = useSelector<ReduxStore, ReduxStore['originatingUrl']>(
    (store) => store.originatingUrl
  );

  // Setup window.nerdwallet.ui.setHeaderProps
  const setHeaderPropsDispatch = useDispatch();

  const setHeaderProps = useCallback(
    (props) => {
      setHeaderPropsDispatch({
        ...props,
        type: SET_GLOBAL_NAV_STATE,
      });
    },
    [setHeaderPropsDispatch]
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.nerdwallet = window.nerdwallet || {};
    window.nerdwallet.ui = window.nerdwallet.ui || {};
    window.nerdwallet.ui.setHeaderProps = setHeaderProps;
    window.document?.dispatchEvent(
      new Event('nw:setHeaderPropsReady', {
        bubbles: true,
      })
    );
  }, [setHeaderProps]);

  const [isAuth0Loading, setIsAuth0Loading] = useState(false);

  const windowResizeDispatch = useDispatch();

  // _.throttle() returns a function, so we useMemo to memoize the returned function rather
  // than useCallback
  const onWindowResize = useMemo(
    () =>
      _.throttle(() => {
        windowResizeDispatch({
          type: WINDOW_RESIZE,
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 50),
    [windowResizeDispatch]
  );
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', onWindowResize);
  }
  // fire an onWindowResize event once to initialize the store
  useEffect(onWindowResize, [onWindowResize]);

  const errorModalDispatch = useDispatch();

  const activeRegStatusNotification = useSelector<
    ReduxStore,
    ReduxStore['activeRegStatusNotification']
  >((store) => store.activeRegStatusNotification);

  const [onHover] = useInteractionDelay();

  const { clearUncacheableTraits } = useAnalytics();

  const shouldShowErrorModal =
    activeRegStatusNotification.status === SHOW_REG_ERROR_STATUS && !isLoggedIn;

  const handleReOpenRegModal = useCallback(() => {
    errorModalDispatch({
      type: HIDE_REG_STATUS,
    });
  }, [errorModalDispatch]);

  const handleContactUs = () => {
    window.open(CONTACT_US_URL, '_blank');
  };

  useEffect(() => {
    // enables reg modals to show by default
    if (!activeRegStatusNotification?.status) {
      handleReOpenRegModal();
    }
  }, [handleReOpenRegModal, activeRegStatusNotification]);

  useEffect(() => {
    // if a user logs in during their session, set the locals storage variable in the browser
    if (isLoggedIn) {
      localStorageHelper.setItem('userHasLoggedIn', true);

      // If the user previously enabled web push,
      // attempt to re-register them upon successful login.
      reregisterWebPush();
    }
    incrementPageViews(originatingUrl);
    localStorageHelper.setItem('previousUrl', originatingUrl);
  }, [isLoggedIn, originatingUrl]);

  useEffect(() => {
    clearUncacheableTraits();
  }, [clearUncacheableTraits]);

  const commonProps = {
    isLoading: isAuth0Loading,
    onHover,
  };

  return (
    <ErrorBoundary>
      {includeAuth?.authButtons && <L1Buttons {...commonProps} />}

      {isAuthModalOverrideSet ||
      activeRegStatusNotification.status !== HIDE_REG_STATUS ? null : (
        <Suspense fallback={null}>
          <OneTapComponent
            setIsAuth0Loading={setIsAuth0Loading}
            showGoogleOneTap={includeAuth?.googleOneTap}
            showNerdwalletModals={includeAuth?.nerdwalletModals}
          />
        </Suspense>
      )}

      {activeRegStatusNotification.status === SHOW_REG_SUCCESS_STATUS ? (
        <Suspense fallback={null}>
          <SuccessModal
            canDismiss
            ctaText={REG_STATUS_SUCCESS_COPY.success.ctaText}
            ctaAlignment="center"
          />
        </Suspense>
      ) : null}

      {activeRegStatusNotification.status === SHOW_REG_INBOX_SUCCESS_STATUS ? (
        <Suspense fallback={null}>
          <SuccessModal
            titleAlignment="left"
            title={REG_STATUS_SUCCESS_COPY['check-inbox'].desktopTextTitle}
            canDismiss
            ctaText={REG_STATUS_SUCCESS_COPY.success.ctaText}
          >
            <p style={{ textAlign: 'left' }}>
              {REG_STATUS_SUCCESS_COPY['check-inbox'].desktopText}
            </p>
          </SuccessModal>
        </Suspense>
      ) : null}

      {shouldShowErrorModal &&
      activeRegStatusNotification.reason ===
        ThirdPartyAuthFailReasons.PROVIDER_ID_CONFLICT ? (
        <Suspense fallback={null}>
          <ErrorModal
            customPrimaryCta={
              <TrackedButton
                buttonName={
                  REG_STATUS_ERROR_COPY.provider_id_conflict.primaryCtaText
                }
                driverLocation={
                  activeRegStatusNotification?.analytics?.driver_location
                }
                buttonType="signin"
                isPrimary
                styleClasses={styles.buttonOverride}
              />
            }
          />
        </Suspense>
      ) : null}

      {shouldShowErrorModal &&
      activeRegStatusNotification.reason === ThirdPartyAuthFailReasons.OTHER ? (
        <Suspense fallback={null}>
          <ErrorModal
            title={REG_STATUS_ERROR_COPY.other.title}
            primaryCtaText={REG_STATUS_ERROR_COPY.other.primaryCtaText}
            primaryCtaOnClick={() => handleReOpenRegModal()}
            secondaryCtaText={REG_STATUS_ERROR_COPY.other.secondaryCtaText}
            secondaryCtaOnClick={() => handleContactUs()}
          />
        </Suspense>
      ) : null}

      {shouldShowErrorModal &&
      activeRegStatusNotification.reason ===
        ThirdPartyAuthFailReasons.USER_MUST_SET_PASSWORD ? (
        <Suspense fallback={null}>
          <ErrorModal
            title={REG_STATUS_ERROR_COPY.user_must_set_password.title}
            content={REG_STATUS_ERROR_COPY.user_must_set_password.content}
            primaryCtaText={
              REG_STATUS_ERROR_COPY.user_must_set_password.primaryCtaText
            }
            primaryCtaOnClick={() => handleReOpenRegModal()}
            fullWidth
          />
        </Suspense>
      ) : null}
    </ErrorBoundary>
  );
};

export default L1ButtonsWrapper;
